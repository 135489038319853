import { useEffect, useRef, useState } from "react";
import Script from "next/script";
import { Banner } from "./style";

const BannerFlotante5 = () => {
  const [isClient, setIsClient] = useState(false);
  const divNav = useRef();

  const openNav = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (divNav.current) {
      //divNav.current.style.width = "100%";
      try {
        googletag?.cmd?.push(() => {
          googletag.pubads().refresh([targetSlot5]);
        });
      } catch (error) {
        console.log({error, targetSlot5});
      }      
    }
  };
  const closeNav = (event) => {
    event.preventDefault()
    event.stopPropagation() 
    /* if (divNav.current) {
      divNav.current.style.width = "0%";
    } */
  };

  useEffect(() => {
    setIsClient(true);
    window.addEventListener("load", function () {
      if (document.getElementById("div-gpt-ad-6905052-2")) {
        document.getElementById("div-gpt-ad-6905052-2").style.pointerEvents = "none";
      }
    })
  }, []);

  return isClient ? (
    <Banner >
      <div id="bannertab" onClick={openNav}>
        <div id="div-gpt-ad-6905052-2" />
      </div>
      <div id="myNav" className="overlay" ref={divNav}>
        {/* <button
          className="closebtn"
          aria-label="boton cerrar"
          onClick={closeNav}
        >
          &times;
        </button> */}
        <div className="overlay-content">
          <div id="div-gpt-ad-6905052-1" />
        </div>
      </div>
    </Banner>
  )
    : (
      <></>
    );
};

export default BannerFlotante5;
