import Head from "next/head";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

export const MetaTagsSubhome = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if (Object.keys(props).length === 0 && !!formatContent) {
    props = {
      ...props,
      ...formatContent?.dataMetaTagsSubhome,
    };
  }

  const { metas = [], title, url, section, imageWeb } = props;

  const dataUrl = (url !== '') ? url : section.toLowerCase();
  
  const metaDescription = dataUrl && dataUrl.includes("recetas") ? 
    "Encontrá en Clarín recetas fáciles, prácticas y originales para preparar los platos más ricos. Buscá recetas de entradas, platos principales, postres, pastelería, salsas y aderezos, bebidas y mucho más." :
    "Las noticias más importantes de " + [section] + ". Leé las Noticias de Hoy en Clarín. Conocé las Últimas noticias de Argentina y del mundo, información actualizada las 24 horas y en español";

  let originalUrl = process.env.NEXT_PUBLIC_DOMAIN_URL + "/" + dataUrl;
  let cleanedUrl = '';
  if (typeof originalUrl === 'string' && originalUrl.trim() !== '') {
    try {
      const urlObj = new URL(originalUrl);
      cleanedUrl = urlObj.origin + urlObj.pathname;
    } catch (error) {
      console.error('Invalid URL:', error);
    }
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title}></meta>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      {/* <meta http-equiv="Content-Language" content="es" /> */}
      <meta name="viewport" content="width=device-width, maximum-scale=5" />
      {/* <meta name="LANGUAGE" content="es" /> */}
      <meta name="distribution" content="Global" />
      <meta name="robots" content="all" />
      <meta name="author" content="Clarin.com" />
      <meta
        name="classification"
        content="noticias, información, videos, diario, newspaper"
      />
      <meta name="Googlebot" content="all" />
      <meta name="generator" content="Clarin.com | Diario Clarín" />
      <meta name="subject" content="Clarin.com | Diario Clarín" />
      <meta name="description" content={metaDescription} />
      <meta
        name="abstract"
        content="Clarin.com noticias de la Argentina y el mundo actualizadas las 24 horas"
      />
      <meta name="msApplication-ID" content="App" />
      <meta
        name="msApplication-PackageFamilyName"
        content="AGEA.MOBILE.Clarin.com_q59wyq0q7cm24"
      />
      <meta name="msvalidate.01" content="CE3C588E6E296E3426712C2530D6922F" />
      <meta property="og:url" content="https://www.clarin.com" />
      <meta property="og:title" content="Clarin.com" />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/Clarin_logotag.png`}
      />
      <meta property="og:site_name" content="Clarin.com" />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={metaDescription}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Clarin.com" />
      <meta
        name="twitter:description"
        content={metaDescription}
      />
      <meta
        name="twitter:image:src"
        content={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/clarin-sharing.png`}
      />
      <meta name="twitter:image:alt" content="clarin.com" />
      <meta name="twitter:creator" content="@clarincom" />
      <meta name="twitter:site" content="@clarincom" />
      <meta name="twitter:domain" content="clarin.com" />
      <meta property="fb:pages" content="156057357762712" />
      <meta property="fb:pages" content="411588568884731" />
      <meta property="fb:pages" content="349870921749715" />
      <meta property="fb:app_id" content="437491989970687" />
      <meta name="application-name" content="Clarin.com"></meta>
      {metas &&
        metas.map(({ tag, content }) => <meta {...datos} />)}
      <link rel="canonical" href={ cleanedUrl } />
      {imageWeb &&
      <> 
        <link
        rel="preload"
        href={imageWeb.desktop.image ?? ""}
        as="image"
        media="(min-width: 975px)" 
        />
        <link
        rel="preload"
        href={imageWeb.mobile.image ?? ""}
        as="image"
        fetchpriority="high"
        media="(max-width: 974px)" 
      />
      </>
      }

    </Head>
  );
};