import Script from "next/script";
import { useState, useEffect } from "react";
import BannerFlotante5 from "./components/BannerFlotante5";
import useAppTrue from "@/hooks/useAppTrue";

const BannerFlotante = (props) => {
  const [isClient, setIsClient] = useState(false);
  const { isPreview, slotId } = props;
  const slot = "flotante"+slotId;

  useEffect(() => {
    function cancelBanner(event) {
      if (typeof event.cancelable !== "boolean" || event.cancelable && event.detail === slot) {
        setIsClient(false);
      } else {
        setIsClient(true);
      }
    }

    if(document.addEventListener("bannerOff", cancelBanner)){
      document.addEventListener("bannerOff", cancelBanner);
    }else{
      setIsClient(true);
    }

  }, []);

  
  const isApp = useAppTrue();
  const closeBanner = () => {
    let bannerClass = `gptFlotante${slotId}`;
    let elements = document.getElementsByClassName(bannerClass);
    while (elements.length > 0) {
      elements[0].remove();
    }
  };

  if (isPreview || isApp) {
    return null;
  }
  if (slotId == "5") {
    return <BannerFlotante5 />;
  }
  return isClient ? (
    <>
      {slotId == "2" && (
        <button
          id="b-main-footer-cerrar"
          aria-label="cerrar"
          className={`gptFlotante${slotId}`}
          onClick={(e) => closeBanner()}
        >
          x
        </button>
      )}

      {slotId == "4" && (
        <button
          id="b-main-top-cerrar"
          className={`gptFlotante${slotId}`}
          onClick={(e) => closeBanner()}
        >
          x
        </button>
      )}

      <div
        id={`div-gpt-ad-flotante${slotId}`}
        className={`gptFlotante${slotId}`}
      >
        <Script
          strategy="afterInteractive"
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `
                    googletag.cmd.push(function() {
                        googletag.display("div-gpt-ad-flotante${slotId}");
                    });
                `,
          }}
        />
      </div>

      <Script
        strategy="afterInteractive"
        suppressHydrationWarning
        dangerouslySetInnerHTML={{
          __html: `
                if(${slotId} == 4){
                    const myScrollFunc = function () {
                        let y = window.scrollY;
                        myID = document.getElementById("b-main-top");
                        if (y >= 100) {
                            myID.classList.add("b-visible");
                            myID.classList.remove("b-hide");
                        } else {
                            myID.classList.remove("b-visible");
                            myID.classList.add("b-hide");

                        }
                    };
                    
                    window.addEventListener("scroll", myScrollFunc);
                }

            `,
        }}
      />
    </>
  ) : (
    <></>
  );
};

export default BannerFlotante;
